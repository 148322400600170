import http, { HttpResponseType } from '@/plugins/http'
import { DataType } from './types'

const LoginService = {
  /**
   * Serviço para autenticar um usuário
   * @param user
   * @param password
   * @returns
   */
  postLogin: (user: string, password: string) => {
    return http
      .post<HttpResponseType<DataType>>(`auth`, {
        login: user,
        password: password
      })
      .then((response) => response.data)
  }
}

export { DataType, UserType } from './types'
export { LoginService }
