import http from '@/plugins/http'
import { ProtocolType } from './types'

const ProtocolService = {
  /**
   * List protocols by user
   * @param userId
   * @returns
   */
  getProtocols: (userId: string) => {
    return http.get<ProtocolType[]>(`reginprotocol/ListByUser?userid=${userId}`)
  },

  /**
   * Create new protocol
   * @param params
   * @returns
   */
  postProtocol: (params: any) => {
    return http.post<ProtocolType[]>(`reginprotocol`, params)
  },

  /**
   * Get protocol by id
   * @param protocolId
   * @returns
   */
  getProtocol: (protocolId: string) => {
    return http.get<ProtocolType>(`reginprotocol/GetProtocol?protocolId=${protocolId}`)
  }
}

export { ProtocolType } from './types'
export { ProtocolService }

