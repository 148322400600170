





















































import { Toast } from '@/mixins/Toast'
import { LoginService, UserType } from '@/services/Auth'
import { Component, Vue } from 'vue-property-decorator'

type ViewType = {
  valid: boolean
  isLoading: boolean
  rules: any
}

type ModelType = {
  login: string
  senha: string
}

type DataModelType = {
  view: ViewType
  model: ModelType
}

@Component({})
export default class LoginForm extends Vue {
  public $refs: {
    form: HTMLFormElement
  }

  public data: DataModelType = {
    view: {
      valid: false,
      isLoading: false,
      rules: {
        required: (value: any) => !!value || 'Campo Obrigatório.'
      }
    },
    model: {
      login: '',
      senha: ''
    }
  }

  public login() {
    if (!this.$refs.form.validate()) {
      return
    }

    this.data.view.isLoading = true

    LoginService.postLogin(this.data.model.login, this.data.model.senha)
      .then((response) => this.authenticate(response))
      .catch(() => Toast.notify('Credenciais inválidas', 'error'))
      .finally(() => (this.data.view.isLoading = false))
  }

  private authenticate(params: any): void {
    this.setLocalStorage(params)
    this.redirect('/')
  }

  private setLocalStorage(data: any): void {
    const token = data.token
    const user: UserType = data

    localStorage.setItem('token', 'Bearer ' + token)
    localStorage.setItem('user', JSON.stringify(user))
  }

  public redirect(page: string): void {
    this.$router.push(page)
  }
}
