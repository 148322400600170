






































import { ProtocolService, ProtocolType } from '@/services/Protocol'
import moment from 'moment'
import { Component, Vue } from 'vue-property-decorator'
import Form from './Form.vue'

type ViewType = {
  headers: any[]
  protocols: ProtocolType[],
  loadingTable: boolean
}

type DataModelType = {
  view: ViewType
}

@Component({
  components: {
    Form
  }
})
export default class LoginForm extends Vue {
  public $refs: {
    form: HTMLFormElement
    newProtocolDialog: any
  }

  public data: DataModelType = {
    view: {
      headers: [
        {
          text: 'Protocolo',
          align: 'start',
          sortable: false,
          value: 'protocol'
        },
        { text: 'Empresa', value: 'company.companyName' },
        { text: 'Situação', value: 'situation' },
        { text: 'Monitorado desde', value: 'requestDate' },
        { text: 'Última Atualização', value: 'lastScrapDate' },
        { text: '', value: 'actions' }
      ],
      protocols: [],
      loadingTable: true
    }
  }

  mounted() {
    this.loadProtocols()
  }

  private loadProtocols() {
    const userId = JSON.parse(localStorage.getItem('user') as string).userId
    ProtocolService.getProtocols(userId).then((response) => {
      this.data.view.protocols = response.data
      this.data.view.loadingTable = false
    })
  }

  public getColorByStatus(status:any) {
    if(status == "PROCESSO FINALIZADO")
      return 'success'

    return 'orange'
  }

  public onClickNewProtocol() {
    this.$refs.newProtocolDialog.onOpenDialog()
  }

  public onClickItem(item: any) {
    this.$router.push(`protocol/${item.reginProtocolId}`)
  }

  public formatDate(date: Date) {
    return moment(String(date)).format('MM/DD/YYYY hh:mm')
  }
}
