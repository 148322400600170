import axios from 'axios'
import router from '../router'

const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  headers: {
    Authorization: `${localStorage.getItem('token')}`,
    'Content-Type': 'application/json'
  }
})

const naoAutenticado = () => {
  localStorage.clear()
  router.replace('auth')
}

const naoAutorizado = () => {
  // router.push('/403')
}

const serverError = () => {
  // aq
}

http.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers['Authorization'] = token
    }
    return config
  },
  (error: any) => {
    return Promise.reject(error)
  }
)

http.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const httpStatus = error.response.status
    switch (httpStatus) {
      case 401: {
        naoAutenticado()
        break
      }

      case 403: {
        naoAutorizado()
        break
      }

      case 500: {
        serverError()
        break
      }
    }

    return Promise.reject(error)
  }
)

type HttpResponseType<DataType> = {
  data: DataType
  statusCode: number
  message: any
}

/**
 * @param params
 * @returns
 */
const URLSearchParams = (params: any) => {
  return Object.entries(Object.assign({}, params))
    .map(([key, value]) => `${key}=${value}`)
    .join('&')
}

export { HttpResponseType, URLSearchParams }
export default http
