import EventBus from '@/plugins/event-bus'

export const Toast = {
  notify(message: string, type = 'success') {
    EventBus.$emit('NOTIFICATION_NOTIFY', {
      type: type,
      message: message,
      snackbar: true
    })
  }
}
