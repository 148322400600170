import { RouteConfig } from 'vue-router'
import Form from './Form.vue'

const routes: Array<RouteConfig> = [
  {
    path: '/dash',
    name: 'Dashboard',
    component: Form
  }
]

export default routes
