

























































































































































import { ProtocolService, ProtocolType } from '@/services/Protocol'
import { Component, Vue } from 'vue-property-decorator'
import { VCol, VContainer, VRow, VToolbar, VToolbarTitle } from 'vuetify/lib'

type ViewType = {
  reginProtocol: ProtocolType
}

type DataModelType = {
  view: ViewType
}

@Component({})
export default class DetailView extends Vue {
  public data: DataModelType = {
    view: {
      reginProtocol: {
        reginProtocolId: '',
        companyId: '',
        userId: '',
        protocol: '',
        action: '',
        situation: '',
        finishedStatus: '',
        legalNature: '',
        city: '',
        requestDate: new Date(),
        finishedDate: new Date(),
        lastScrapDate: new Date(),
        processed: false,
        company: {
          companyId: '',
          cnpj: '',
          companyName: '',
          fantasyName: '',
          legalNature: '',
          postage: '',
          billingRange: '',
          state: '',
          refreshOrigin: '',
          subscriptionType: '',
          foundationDate: new Date(),
          autenticationDate: new Date(),
          numberOfEmployees: 0,
          taxpayerType: 0,
          fiscalType: 0,
          capitalValue: 0,
          isMEI: false
        },
        institutes: []
      }
    }
  }

  mounted() {
    this.loadProtocol()
  }

  private loadProtocol() {
    ProtocolService.getProtocol(this.$route.params.id).then((response) => {
      this.data.view.reginProtocol = response.data
    })
  }
}
