




















import Vue from 'vue'
import Toast from '@/components/Toast'

export default Vue.extend({
  name: 'App',

  components: {
    Toast
  },

  data: () => ({})
})
