import { RouteConfig } from 'vue-router'
import DetailView from './Detail.vue'
import Form from './List.vue'

const routes: Array<RouteConfig> = [
  {
    path: '/protocols',
    name: 'Protocolos',
    component: Form
  },
  {
    path: '/protocol/:id',
    name: 'Protocolo',
    component: DetailView
  }
]

export default routes
