import Vue from 'vue'
import VueRouter from 'vue-router'
import routerAuth from '../views/Auth/router'
import routerDashboard from '../views/Dashboard/router'
import routerProtocol from '../views/Protocol/router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    ...routerAuth,
    ...routerProtocol,
    ...routerDashboard,
    {
      path: '*',
      redirect: '/dash'
    }
  ]
})

router.beforeEach((to, from, next) => {
  // TODO ALAN
  if (to.fullPath != '/auth') {
    const user = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user') as string)
      : null

    if (!user) {
      next('/auth')
      return
    }
  }

  next()
})

export default router
