





















import { Component, Prop, Vue } from 'vue-property-decorator'
import EventBus from '@/plugins/event-bus'

type ConfigType = {
  type: string
  message: string
  snackbar: boolean
}

type DataType = {
  show: boolean
  color: string
  message: string
  mode: string
  snackbar: boolean
  timeout: number
  x?: any
  y: string
}

@Component({})
export default class Toast extends Vue {
  public data: DataType = {
    message: '',
    show: false,
    color: '',
    mode: '',
    snackbar: false,
    timeout: 6000,
    x: null,
    y: 'top'
  }

  mounted() {
    EventBus.$on('NOTIFICATION_NOTIFY', (config: ConfigType) => {
      this.data.color = config.type
      this.data.message = config.message
      this.data.snackbar = config.snackbar
    })
  }
}
