import { RouteConfig } from 'vue-router'
import LoginForm from './Login.vue'
import RecoverPassword from './RecoverPassword.vue'

const routes: Array<RouteConfig> = [
  {
    path: '/auth',
    name: 'Login',
    component: LoginForm,
    meta: {
      public: true
    }
  },
  {
    path: '/recover-password',
    name: 'Recover Password',
    component: RecoverPassword,
    meta: {
      public: true
    }
  }
]

export default routes
