























import { Toast } from '@/mixins/Toast'
import { ProtocolService } from '@/services/Protocol'
import { Component, Vue } from 'vue-property-decorator'

type DataType = {
  view: ViewDataType
  dialog: boolean
}

type ViewDataType = {
  protocol: string
  isLoading: boolean
  rules: any
}

@Component({})
export default class FormDialogView extends Vue {
  public $refs: {
    form: HTMLFormElement
  }

  public data: DataType = {
    view: {
      protocol: '',
      isLoading: false,
      rules: {
        required: (value: any) => !!value || 'Campo Obrigatório.'
      }
    },
    dialog: false
  }

  onOpenDialog() {
    this.data.dialog = true
  }

  onCloseDialog() {
    this.data.dialog = false
    this.data.view.protocol = ''
  }

  submitNewProtocol() {
    if (!this.$refs.form.validate()) {
      return
    }

    this.data.view.isLoading = true
    const params = {
      protocol: this.data.view.protocol
    }

    ProtocolService.postProtocol(params)
      .then(() => {
        this.onCloseDialog()
        Toast.notify('Protocolo adicionado')
      })
      .catch(() =>
        Toast.notify('Erro ao cadastrar protocolo, tente novamente', 'error')
      )
      .finally(() => (this.data.view.isLoading = false))
  }
}
