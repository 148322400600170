var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.data.view.headers,"items":_vm.data.view.protocols,"items-per-page":15,"loading":_vm.data.view.loadingTable,"loading-text":"Aguarde... Carregando dados"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Lista de Protocolos")]),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.onClickNewProtocol}},[_vm._v(" Criar Protocolo ")])],1)]},proxy:true},{key:"item.situation",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColorByStatus(item.situation),"dark":""}},[_vm._v(" "+_vm._s(item.situation)+" ")])]}},{key:"item.requestDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.requestDate))+" ")]}},{key:"item.lastScrapDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.lastScrapDate))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.onClickItem(item)}}},[_vm._v(" mdi-chart-timeline-variant ")])]}}],null,true)}),_c('Form',{ref:"newProtocolDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }